.App {
  background-color: #0a0a0a;
}

#proj-intro, #proj-overview, #vault-intro, .content {
  margin-left: 100px;
}

#proj-intro, #vault-intro {
  display: flex;
  flex-direction: column;
  position: relative;
}

#vault-intro {
  background-color: #02142c;
}

#vault-intro h1 {
  z-index: -1;
  color: transparent;
  position: relative;
  font-size: 8rem;
  display: flex;
  text-align: center;
  top: 50%;
  width: calc(100vw - 100px);
  justify-content: center;
  -webkit-text-stroke: 2px white;
}

.vaultheader {
  position: absolute;
}

#gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#gallery p{
  color: white;
  margin-bottom: 5%;
}

#gallery img {
  width: 500px;
}

#tsparticles {
  z-index: 0;
  height: 100vh;
  background-color: transparent;
}

.backbtn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 5%;
  left: 5%;
  z-index: 2;
  background-color: transparent;
  border: none;
  color: white;
  outline: none;
}

.backbtn:hover {
  cursor: pointer;
  text-decoration: underline;
}

.backbtn svg {
  color: white;
  transform: scale(2);
  margin-right: 4px;
}

#proj-cover {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100vh;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}

.becuhero {
  background-image: url('bgimages/becuprojhero.png');
}

.f1hero {
  background-image: url('bgimages/f1projhero.png');
}

.tidyhero {
  background-image: url('bgimages/tidyprojhero.png');
}

.yoglohero {
  background-image: url('bgimages/yogloprojhero.png');
}

.sethero {
  background-image: url('bgimages/sethero.png');
}

.pokedexhero {
  background-image: url('bgimages/pokedexhero.png');
}

.bnbhero {
  background-image: url('bgimages/Journeybg.png');
  background-position: center;
}

#proj-cover img {
  position: relative;
  z-index: 1;
  transform: scale(.5);
}

.proj-revealer {
  position: absolute;
  background-color: #0a0a0a;
  right: 0;
  height: 101%;
  width: 0%;
  transform:
    rotate(10deg),
    scale(1.5);
  z-index: 0;
}

#proj-cover h1 {
  margin: 0;
  z-index: 1;
  font-size: 8rem;
  color: transparent;
  text-align: center;
  -webkit-text-stroke: 2px white;
}

#proj-overview {
  display: flex;
  width: calc(100vw - 100px);
  bottom: -5%;
}

#proj-name {
  flex-direction: column;
  justify-content: center;
  background-color: #ececec;
  width: 350px;
  padding: 5%;
}

#proj-name div {
  position: relative;
}

#proj-name > div::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: -120px;
  height: 100px;
  width: 1px;
  background-color: #707070;
}

#number {
  font-size: 2rem;
  letter-spacing: -12px;
  font-weight: bold;
  color: transparent;
  -webkit-text-stroke: 1px white;
  writing-mode: vertical-rl;
  text-orientation: upright;
  display: block;
  position: absolute;
  left: calc(0% - 22px);
  top: -220px;
  margin: 0;
}

#proj-name p, #proj-overview p:nth-child(odd) {
  color: #707070;
  margin: 0;
}

#proj-name h1 {
  font-size: 3rem;
  margin: 0;
}

#proj-details {
  background-color: #0a0a0a;
  width: 65%;
  align-items: center;
  justify-content: space-around;
}

#proj-name, #proj-details {
  display: flex;
}

#proj-details p:nth-child(even) {
  color: white;
  margin: 4px 0;
}

.content {
  position: absolute;
  width: calc(100% - 100px);
  padding: 10% 0;
  background-color: #0a0a0a;
  z-index: 3;
}

.process-section img {
  width: 80%;
  margin: 5% 10%;
}

.white {
  background-color: #f5f5f5;
}

.process-section {
  padding: 10% 0 0% 0;
}

.process-title {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #292929;
  margin: 0 10%;
}

.process-title p{
  color: #707070;
  font-size: .8rem;
}

.process-title h1 {
  font-size: 3rem;
  padding-left: 48px;
  color: white;
}

.process-text {
  margin: 5% 20%;
  line-height: 1.75;
  color: #dadada;
}

.process-text h2{
  position: relative;
  margin-top: 3rem;
  margin-bottom: 0;
  color: white;
  width: fit-content;
}

.process-text h3{
  position: relative;
  text-decoration: underline;
  font-style: italic;
  margin-top: 32px;
  margin-bottom: 0;
  color: #ececec;
}

#projectVideo {
  width: 80%;
  margin: 5% 10%;
}

.mockupVideo {
  height: 600px;
  margin: 5% 10%;
  box-shadow: 0 4px 30px rgba(0, 0, 0, .25);
}

.mockupVid {
  display: flex;
  width: 80%;
  margin: 5% 10%;
  background-size: cover;
  background-repeat: repeat;
}

.webuiVideo {
  height: 400px;
  margin: 5% 10% 0%;
}

.videotxt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  color: #dadada;
  line-height: 1.5;
  padding: 10%;
  background-color: rgba(0, 0, 0, .5);
  backdrop-filter: blur(8px);
}

.videotxt h2 {
  color: white;
  width: fit-content;
}

.overlay {
  position: relative;
  z-index: 10;
  height: 100%;
  width: 100%;
  background-color: rgba(10, 10, 10, .95);
}

.full-width img{
  width: 100%;
  margin: 0;
}

.statement {
  border-left: 3px solid;
  padding-left: 16px;
}

#becuS {
  border-color: #ffb200;
}

#f1S {
  border-color: #EF3F3E;
}

#tidyS {
  border-color: #1ACB90;
}

#yogloS {
  border-color: #73A7E4;
}

#defaultS {
  border-color: #5498fd;
}

.flex-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 10%;
}

.flex-grid img, .flex-grid2 img, .flex-grid3 img {
  width: calc(50% - 10px);
  margin: 5px;
  background-color: transparent;
}

.flex-grid2 {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  background-color: #f5f5f5;
}

.flex-grid3 {
  display: flex;
  overflow-x: scroll;
  background-color: #f5f5f5;
  padding: 5%;
}

.flex-grid3 img {
  width: calc(25%);
  margin: 5px;
}

.flex-grid4 {
  display: flex;
  background-color: #f5f5f5;
  padding: 5%;
}

.highlight {
  border-bottom: 2px solid;
}

.bw {
  filter: grayscale(1);
}

#otherproj {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20% 0 0 0;
}

.cta, .cta div:nth-child(1), .cta div:nth-child(2) {
  display: flex;
  cursor: pointer;
}

.cta:hover svg{
  transform: translateX(20px);
}

.cta div:nth-child(1){
  width: 300px;
  height: 300px;
  background-color: #ececec;
  align-items: center;
  overflow: hidden;
}

.cta div:nth-child(1) img {
  width: 100%;
  margin: 0;
  transform: scale(2.5);
}

.cta div:nth-child(2) p {
  font-size: .8rem;
  color: #707070;
  text-decoration: underline;
}

#previous div:nth-child(2) {
  flex-direction: column;
  justify-content: center;
  background-color: #ececec;
  padding: 5%;
  width: 150px;
  color: #0a0a0a;
  position: relative;
  z-index: 1;
  box-shadow: -10px 0 8px rgba(10, 10, 10, .25);
}

#next div:nth-child(2) {
  flex-direction: column;
  justify-content: center;
  background-color: #ececec;
  padding: 5%;
  width: 150px;
  color: #0a0a0a;
  position: relative;
  z-index: 1;
  box-shadow: 10px 0 8px rgba(10, 10, 10, .25);
}

.cta svg {
  margin: 10px 0 10px 0;
  color: #707070;
  transition: ease-out .2s;
}

#next {
  flex-direction: row-reverse;
}

.barsection{
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 5%;
  background-color: black;
  color: #ececec;
}

.tidygrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  text-align: left;
}

.finding {
  width: 25%;
  padding: 0% 2%;
}

.barsection h3 {
  font-weight: 500;
  text-decoration: underline;
}

.finding p {
  border-left: 3px solid ;
  padding-left: 16px;
  color: #ececec;
  font-style: italic;
}

.finding strong {
  color: #707070;
}

.gifcontainer{
  display: flex;
  margin: 0 30px;
}

.gifcontent {
  padding: 5%;
}

.gifcontent p:nth-child(1){
  font-size: .8rem;
  color: #707070;
}

.gifcontent p:nth-child(3){
  padding-left: 16px;
  border-left: 4px solid #1ACB90;
}

.gifcontent h2 {
  margin-top: 0;
}

.gifcontainer img{
  width: 250px;
  margin: 0;
}

.reverse {
  flex-direction: row-reverse;
}

blockquote {
  padding: 0% 16px;
  border-left: 4px solid #ffb200;
}

iframe {
  margin: 5% 10%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 80%;
  height: 600px;
}

#colorpalette {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 5% 10%;
}

.grid-section1 {
  width: 125px;
  height: 150px;
  display: flex;
  color: white;
  padding: 0 5%;
  margin-top: 10px;
  align-items: flex-end;
  justify-content: flex-start;
}

.hidden {
  display: none;
}

.blue {
  background-color: #0D144D;
}

.yellow {
  background-color: #ffb200;
}

.green {
  background-color: #00CD86;
}

.red {
  background-color: #FF2960;
}

@media only screen and (max-width: 768px) {
  #splash, main {
    margin-left: 0px;
  }

  .main-bio-container{
    position: relative;
    margin-left: 0px;
    background-color: #0a0a0a;
    z-index: 2;
  }

  #otherproj {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    justify-content: center;
    margin: 20% 0;
  }

  #number {
    left: calc(0% - 18px);
    top: -190px;
  }

  .grid-section {
    width: 100%;
  }

  .mockupVideo {
    height: 500px;
    box-shadow: none;
  }

  .webuiVideo {
    height: 300px;
    margin: 5% 10%;
  }

  .mockupVid {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 5% 10%;
    background-size: cover;
    background-repeat: repeat;
}

  .becuVid {
    flex-direction: column;
  }

  .videotxt {
    width: 80%;
  }
}

@media only screen and (max-width: 1000px) {
  .content {
    position: absolute;
    width: 100%;
    padding: 10% 0;
    background-color: #0a0a0a;
    z-index: 3;
    margin-left: 0;
  }

  .backbtn {
    display: none;
  }

  #proj-cover img {
    position: relative;
    z-index: 1;
    transform: scale(.35);
  }

  #proj-name > div::after {
    left: calc(0% - 18px);
    top: -190px;
}

  #proj-name {
    flex-direction: column;
    justify-content: center;
    background-color: #ececec;
    width: 90%;
    height: 100px;
    padding: 5%;
  }

  #proj-details {
    background-color: #0a0a0a;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    height: 100px;
  }

  #proj-intro, #proj-overview, #vault-intro, .content {
    margin-left: 0;
}

  #proj-overview {
    display: flex;
    flex-direction: column;
    width: 100%;
    bottom: -5%;
  }

  .gifcontainer img{
    width: 150px;
    margin: 0;
  }

  #vault-intro h1 {
    font-size: 8rem;
    top: 50%;
    width: 100vw;
  }
}

@media only screen and (max-width: 425px) {
  html {
    font-size: 14px;
  }

  #proj-cover {
    height: 80vh;
  }

  #proj-cover img {
    position: relative;
    z-index: 1;
    transform: scale(.2);
}

  #proj-overview {
    display: flex;
    flex-direction: column;
    width: 100%;
    bottom: -5%;
  }

  #proj-name > div::before {
    top: -60px;
    height: 55px;
  }

  #proj-name > div::after {
    left: calc(0% - 18px);
    top: -130px;
  }

  .process-title {
    margin: 0 5%;
  }

  .process-title h1 {
    padding-left: 0px;
  }

  .process-title p{
    display: none;
  }

  .process-text {
    margin: 5% 5%;
  }

  .process-section img {
    width: 90%;
    margin: 5% 5%;
  }

  .flex-grid3 {
    margin: 20% 0;
  }

  .flex-grid3 img {
    width: calc(70% - 10px);
    margin: 5px;
  }

  .cta div:nth-child(1){
    width: 150px;
    height: 200px;
    background-color: #ececec;
    align-items: center;
    overflow: hidden;
  }

  #previous div:nth-child(2) {
    flex-direction: column;
    justify-content: center;
    background-color: #ececec;
    padding: 5%;
    color: #0a0a0a;
    position: relative;
    z-index: 1;
    box-shadow: -10px 0 8px rgba(10, 10, 10, .25);
  }

  #previous {
    margin-bottom: 10px;
  }

  #next div:nth-child(2) {
    flex-direction: column;
    justify-content: center;
    background-color: #ececec;
    padding: 5%;
    color: #0a0a0a;
    position: relative;
    z-index: 1;
    box-shadow: 10px 0 8px rgba(10, 10, 10, .25);
  }

  .finding {
    width: 100%;
    padding: 0% 2%;
  }

  .gifcontainer {
    justify-content: center;
  }

  .gifcontainer img {
    width: 150px;
    margin: 0;
  }

  .flex-grid4 {
    flex-direction: column;
  }

  .reverse {
    flex-direction: column;
  }

  #number {
    left: calc(0% - 18px);
    top: -130px;
  }

  .becuVid {
    width: 90%;
    margin: 5%;
   }

   #vault-intro h1 {
    font-size: 4rem;
    width: 100vw;
  }

  .vaultheader {
    position: absolute;
    top: 30%;
  }

  #gallery img {
    width: 90%;
  }

  .mockupVid {
    width: 90%;
    margin: 5% 5%;
  }

  .webuiVideo {
    width: 100vw;
    margin: 2.5% 0%;
  }

  #colorpalette {
    margin: 5%;
  }

  .grid-section1 {
    width: 100%;
    height: 100px;
  }
}