html {
  font-size: 18px;
  background-color: #0a0a0a;
}

.App {
  font-family: 'bilo', sans-serif;
  overflow: hidden;
}

#landing, main, footer{
  margin-left: 100px;
}

/* header */
#landing {
  position: relative;
  display: flex;
  align-items: center;
  height: 100vh;
  background-image: url('bgimages/testbg.png');
  background-size: contain;
  background-color: #0a0a0a;
}

#revealer {
  position: absolute;
  background-color: #0a0a0a;
  width: 0%;
  height: 81vh;
  top: 0;
  right: 0;
  z-index: 1;
}

#image-container {
  position: absolute;
  top: 0;
  right: 0;
  height: 80vh;
  width: 70vw;
}

#overflow {
  height: 80vh;
  width: 70vw;
  overflow: hidden;
}

#image-container img{
  height: 100%;
  filter: grayscale(1);
}

#intro {
  position: absolute;
  left: 10vw;
  width: 40vw;
  z-index: 1;
}

#intro p, #intro h3 {
  color: white;
  margin: 0;
  line-height: 1.5;
}

#intro h1 {
  color: white;
  font-size: 3rem;
  margin: 1rem 0;
  font-weight: 900;
  line-height: 1;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
}

#intro h1 span{
  font-weight: 700;
  font-size: 1rem;
  padding: 10px 16px 0px 0px;
}

#intro p {
  margin-top: 2rem;
}

#intro .intro-active {
  color: #5498fd;
}

.line {
  margin-top: 2rem;
  height: 4px;
  width: 72px;
  background-color: #5498fd;
}

#block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: -20vh;
  height: 35%;
  width: 70%;
  background-color: transparent;
  z-index: 2;
}

#down {
  position: relative;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 20%;
  color: white;
  background-color: #5498fd;
}

#down svg {
  height: 50px;
  width: 50px;
}

#snippet {
  position: relative;
  right: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  width: 80%;
  height: 100%;
  background-color: #ececec;
  border-left: 10px solid #5498fd;
}

#snippet h3::before {
  position: absolute;
  content: '';
  display: block;
  height: 20px;
  width: 20px;
  left: 10%;
  bottom: 30%;
  border-left: 2px solid black;
  border-bottom: 2px solid black;
}

#snippet h3::after {
  position: absolute;
  content: '';
  display: block;
  height: 20px;
  width: 20px;
  right: 10%;
  top: 30%;
  border-right: 2px solid black;
  border-top: 2px solid black;
}

#text-revealer {
  position: absolute;
  height: 0%;
  width: 100%;
  right: 0;
  bottom: 0;
  background-color: #ececec;
}

/* Labels */
.label {
  margin: 0;
  color: White;
  width: 100%;
  display: flex;
  align-items: center;
  line-height: normal;
}

.label:after {
  content: "";
  width: 100%;
  display: block;
  border-bottom: 1px solid white;
  margin-left: 30px;
  flex: 1;
}

.label2 {
  margin: 10% 0;
  color: lightgray;
  width: 50px;
  display: flex;
  align-items: center;
  line-height: normal;
}

.label2:before {
  content: "";
  width: 50px;
  display: block;
  border-bottom: 1px solid lightgray;
  margin-right: 8px;
  flex: 1;
}

/* Navigation */
.mobile {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  right: 0;
  height: 80px;
  width: 80px;
  background-color: transparent;
  z-index: 10;
}

#menu {
  display: flex;
  align-items: center;
  height: 35px;
}

#hamburger {
  position: relative;
  left: -80px;
  height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0a0a0a;
  opacity: 1;
  cursor: pointer;
}

#menu-btn {
  position: relative;
  width: 24px;
  height: 3px;
  background-color: white;
  border-radius: 5px;
}

#menu-btn::before {
  right: 0;
}

#menu-btn::after {
  left: 0;
}

#menu-btn::before,
#menu-btn::after {
  content: '';
  position: absolute;
  width: 12px;
  height: 3px;
  border-radius: 5px;
  background-color: white;
  transition: ease-out .2s;
}

#menu-btn::before {
  transform: translateY(-8px);
}

#menu-btn::after {
  transform: translateY(8px);
}

#hamburger:hover #menu-btn::before,
#hamburger:hover #menu-btn::after {
  width: 24px;
}

#color-expand {
  position: absolute;
  width: 30vw;
  right: -30vw;
  height: 100vh;
}

#menu-expand {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 27vw;
  right: -30vw;
  height: 100vh;
  background-color: #0a0a0a;
}

#close-btn {
  height: 45px;
  width: 45px;
  color: white;
}

#menu-expand li {
  width: fit-content;
  padding: 8px;
  list-style: none;
  font-size: 1.5rem;
  font-weight: 900;
  color: white;
}

#menu-socials {
  color: white;
  border-top: 1px solid #292929;
  padding: 5% 0;
  margin: 0 40px;
  display: flex;
  justify-content: space-around;
}

#menu-socials svg{
  color: white;
  transition: ease-out .2s;
}

#menu-socials svg:hover{
  color: #5498fd;
  transform: translateY(-4px);
}

.logo {
  position: fixed;
  top: 6px;
  width: 45px;
  left: 5%;
  color: white;
  transition: ease-out .3s;
  z-index: -1;
}

.web {
  width: 100px;
  position: fixed;
  height: 100vh;
  background-color: black;
  transition: ease-in-out .3s;
  z-index: 10;
  border-right: 1px solid #292929;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.web img{
  position: relative;
  padding: 16px;
  width: 60px;
}

.web ul {
  display: flex;
  position: absolute;
  top: 250px;
}

.web li{
  display: inline-block;
  list-style: none;
  margin: 0 12px;
}

.web ul {
  transform: rotate(-90deg);
  /* padding-left: 32px; */
}

.link-underline::after {
  content: '';
  display: block;
  width: 0;
  height: 4px;
  background: #5498fd;
  transition: ease-out .2s;
}

.link-underline:hover::after {
  width: 100%;
  transition: ease-out .2s;
  cursor: pointer;
}

.link-underline:hover {
  transition: ease-out .3s;
  cursor: pointer;
  color: white;
}

/* .current {
  color: white;
}

.current::after {
  display: block;
  width: 100%;
  height: 4px;
  background: #5498fd;
} */

.link {
  color: #707070;
}

a {
  text-decoration: none;
  color: #707070;
}

a:hover {
  text-decoration: none;
  color: white;
}

/* Pagination */
#tab {
  position: fixed;
  padding-left: 0;
  top: 40%;
  left: 5%;
}

#tab a {
  text-decoration: none;
  color: white;
}

#tab ul {
  position: fixed;
  top: 40%;
  left: 0;
  list-style: none;
  padding: 0 0 0 0;
}

#tab li {
  display: flex;
  align-items: center;
}

#tab p {
  margin: 0;
  font-size: 12px;
  margin-left: 5px;
  font-weight: 500;
}

#tab li div{
  background-color: rgb(143, 143, 143);
  width: 1px;
  height: 25px;
  margin: 4px;
}

#tab .active {
  background-color: white;
  width: 3px;
  transition: ease-in .2s;
}

.hidden {
  display: none;
}

/* Button Stuff */
.btn {
  font-weight: bold;
  color: white;
  background-color: black;
  display: inline-block;
  border-color: transparent;
  margin: 10px 10px;
  padding: 12px 48px;
  width: 150px;
  cursor: pointer;
  transition: ease-out 0.3s;
  position: relative;
}

.btn:hover {
  background-color: #665df5;
}

.btn:hover::after {
  width: 99%;
  height: 0;
  right: 0;
  border-color: #665df5;
}

.btn::after {
  position: absolute;
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border-width: 2px;
  border-style: solid;
  right: -8px;
  top: -8px;
  border-bottom: none;
  border-left: none;
  border-color: black;
  transition: .3s;
}

.btn:hover::before {
  width: 99%;
  height: 0;
  left: 0;
  border-color: #665df5;
}

.btn::before {
  position: absolute;
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border-width: 2px;
  border-style: solid;
  left: -8px;
  bottom: -8px;
  border-top: none;
  border-right: none;
  border-color: black;
  transition: .3s;
}

/* Project Containers */
main {
  padding: 10%;
  background-color: #0a0a0a;
}

#projects {
  position: relative;
  background-color: #0a0a0a;
}

#projects h3{
  font-size: 3rem;
  color: White;
}

.project {
  display: flex;
  margin: 20% 0 20% 0;
  width: auto;
  height: 60vh;
  max-height: 500px;
  background-color: #202020;
  padding: 2.5% 5%;
  transition: cubic-bezier(0, 1, .9, 1) .3s;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}

.project section {
  width: 100%;
}

.project section img{
  position: absolute;
  right: 10%;
  top: -15%;
  height: 105%;
  transition: cubic-bezier(0, 1, .9, 1) .3s;
}

.project div {
  width: 45%;
  margin-top: 3rem;
  line-height: 1.5;
}

.project button{
  position: absolute;
  bottom: 7%;
  left: 4%
}

.project:hover {
  transform: scale(.98);
}

.project:hover img {
  transform: scale(1.05);
}

.project:hover #colorblock {
  width: 100%;
}

.project h1 {
 font-size: 2rem;
 font-weight: 900;
 margin: 2% 0;
}

#num {
  position: absolute;
  top: -32px;
  left: 5%
}

.project p {
  margin: 0;
 }

#type {
   color: #707070;
   text-transform: uppercase;
}

#proj {
  color: #757575;
  font-size: .8rem;
}

#date em{
  margin: 0;
  color: #707070;
  position: absolute;
  text-align: left;
  top: 18%;
  right: 0;
  transform: rotate(90deg);
}

#colorblock {
  position: absolute;
  bottom: -15px;
  left: 0;
  height: 15px;
  width: 0;
  transition: cubic-bezier(0, 1, .9, 1) .4s;
}

/* CardType2 */
.scrolling-wrapper {
  padding: 5% 0;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  border-left: 1px solid #292929;
  scroll-behavior: smooth;
}

.scrolling-wrapper::-webkit-scrollbar {
  display: none;
}

.card {
  position: relative;
  margin: 0 25px;
  flex: 0 0 auto;
  width: 400px;
  overflow: hidden;
  cursor: pointer;
  transition: ease-out .3s;
}

.card:hover img {
  transition: ease-out .2s;
  transform: scale(1.05);
}

.card:hover h2 {
  text-decoration: underline;
}

.hero {
  overflow: hidden;
  width: 100%;
}

.hero img {
  width: 100%;
}

.card p {
  margin-bottom: 8px;
  color: #707070;
  text-transform: uppercase;
}

.card h2{
  margin-top: 8px;
  margin-bottom: 0;
  color: white;
}

.button-container {
  text-align: right;
  right: 0;
}

#forward, #back {
  border-radius: 0;
  background-color: #0a0a0a;
  border: 1px solid white;
  margin: 8px;
  padding: 0;
  height: 60px;
  width: 60px;
  transition: ease-out .2s;
  cursor: pointer;
}

#forward:hover, #back:hover {
  background-color: white;
  border: 1px solid white;
}

#forward:hover svg, #back:hover svg{
  color: #0a0a0a;
}

.button-container svg{
  height: 30px;
  width: 30px;
  color: white;
}

/* Footer */
footer {
  position: sticky;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10%;
  height: calc(100vh - 80px);
  z-index: 9;
  background-image: url('bgimages/testbg.png');
  background-size: contain;
  background-color: #ececec;
}

footer h1{
  margin-top: 0;
  margin-bottom: 1rem;
  color: #0a0a0a;
}

footer h3{
  color: #a8a8a8;
  margin-bottom: 8px;
}

footer div p{
  margin: 0;
  width: fit-content;
  border-bottom: 1px dotted #5498fd;
  margin: 8px 0;
  cursor: pointer;
  transition: ease-out .2s;
}

footer div p:hover{
  background-color: rgba(84, 152, 253, .25);
}

#copy {
  padding-top: 4px;
  text-align: center;
  width: 80%;
  position: absolute;
  bottom: 5%;
  font-size: .75rem;
  border-top: 1px solid #0a0a0a;
}

#foot-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

#foot-container > div{
  padding-right: 8%;
}

/* social section*/
#socials {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 16px;
  z-index: 5;
}

#socials svg {
  width: 18px;
  height: 18px;
  margin: 6px 0;
  cursor: pointer;
  transition: ease-out .2s;
  color: white;
}

#socials svg:hover {
  color: #5498fd;
  transform: translateX(4px);
}

/* Colors */
.default-color {
  background-color: #5498fd;
  color: #5498fd;
}

.f1:hover::before, .f1:hover::after {
 border-color: #EF3F3E;
}

.f1R, .f1:hover {
  background-color: #EF3F3E;
}

.tidy:hover::before, .tidy:hover::after {
  border-color: #14cc91;
}

.tidyG, .tidy:hover {
  background-color: #14cc91;
}

.yoglo:hover::before, .yoglo:hover::after {
  border-color: #74a9e5;
}

.yoglo:hover {
  background-color:#74a9e5;
}

.yogloB {
  /* background-color: #a9d7de; */
  background-color:#74a9e5;
}

.becu:hover::before, .becu:hover::after {
  border-color: #FFB200;
}

.becuB, .becu:hover {
  background-color:#0D144D;
}

/* Animations */
.kenburns-right {
	-webkit-animation: kenburns-right 20s ease-out infinite alternate both;
	        animation: kenburns-right 20s ease-out infinite alternate both;
}

@-webkit-keyframes kenburns-right {
  0% {
    -webkit-transform: scale(1) translate(0, 0);
            transform: scale(1) translate(0, 0);
    -webkit-transform-origin: 84% 50%;
            transform-origin: 84% 50%;
  }
  100% {
    -webkit-transform: scale(1.10) translateX(20px);
            transform: scale(1.10) translateX(20px);
    -webkit-transform-origin: right;
            transform-origin: right;
  }
}
@keyframes kenburns-right {
  0% {
    -webkit-transform: scale(1) translate(0, 0);
            transform: scale(1) translate(0, 0);
    -webkit-transform-origin: 84% 50%;
            transform-origin: 84% 50%;
  }
  100% {
    -webkit-transform: scale(1.10) translateX(20px);
            transform: scale(1.10) translateX(20px);
    -webkit-transform-origin: right;
            transform-origin: right;
  }
}

.heartbeat {
	-webkit-animation: heartbeat 1.5s ease-in-out infinite both;
	        animation: heartbeat 1.5s ease-in-out infinite both;
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

@media only screen and (max-width: 1000px) {
  html {
    font-size: 16px;
  }

  #landing, main, footer {
    margin-left: 0px;
}

  /* projects */
  main {
    padding: 80px 10% 80px 10%;
  }

  .project section {
    width: 100%;
  }

  .project section img {
    position: absolute;
    right: 10%;
    top: -10%;
    height: 100%;
    transition: cubic-bezier(0, 1, .9, 1) .3s;
}

  .web {
    display: none;
  }

  .mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    right: 0;
    height: 60px;
    width: 100%;
    background-color: #0a0a0a;
    transition: ease-out .3s;
    z-index: 10;
    backdrop-filter: blur(8px);
  }

  #hamburger {
    position: relative;
    left: -60px;
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0a0a0a;
    cursor: pointer;
  }
}

@media only screen and (max-width: 850px) {
  .project button{
    display: none;
  }

  .project section img {
    position: absolute;
    right: 10%;
    top: -10%;
    height: 90%;
    transition: cubic-bezier(0, 1, .9, 1) .3s;
  }

  .project {
    height: 60vh;
  }
}

@media only screen and (max-width: 768px) {
  html {
    font-size: 14px;
  }

  #landing, main, footer{
    margin-left: 0px;
  }

  /* header */
  #image-container {
    position: absolute;
    top: 0;
    right: 0;
    height: 80vh;
    width: 80vw;
  }

  #overflow {
    height: 80vh;
    width: 80vw;
    overflow: hidden;
  }

  #intro {
    position: absolute;
    left: 10vw;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
  }

  #block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: -20vh;
    height: 30%;
    width: 80%;
    background-color: transparent;
  }

  #down svg {
    height: 40px;
    width: 40px;
  }

  /* projects */
  main {
    padding: 80px 10% 80px 10%;
  }

  .project {
    display: flex;
    margin: 30% 0;
    width: auto;
    height: 50vh;
    background-color: #292929;
    padding: 2.5% 5%;
    transition: cubic-bezier(0, 1, .9, 1) .3s;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .project section {
    width: 100%;
  }

  .project section img{
    position: absolute;
    right: 10%;
    top: -15%;
    height: 105%;
    transition: cubic-bezier(0, 1, .9, 1) .3s;
  }

  #date em{
    position: absolute;
    text-align: left;
    top: 18%;
    right: -10px;
    transform: rotate(90deg);
  }

  #colorblock {
    position: absolute;
    display: block;
    bottom: -15px;
    left: 0;
    height: 15px;
    width: 100% ;
    z-index: -1;
    transition: cubic-bezier(0, 1, .9, 1) .4s;
  }

  /* social section*/
  #socials {
    display: none;
  }
}

@media only screen and (max-width: 425px) {
  html {
    font-size: 14px;
  }

  /* Header */
  #image-container {
    position: absolute;
    top: 0;
    right: 0;
    height: 80vh;
    width: 100vw;
  }

  #overflow {
    height: 80vh;
    width: 100vw;
    overflow: hidden;
  }

  #image-container img{
    position: absolute;
    left: -110%;
    bottom: -10%;
  }

  #intro {
    position: absolute;
    left: 10vw;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
  }

  #intro h1 {
    color: white;
    font-size: 2rem;
    margin: .5rem 0;
    font-weight: 900;
    line-height: 1;
    display: flex;
    align-items: flex-start;
    cursor: pointer;
  }

  #intro h1 span{
    font-size: .75rem;
    padding: 0.35rem .5rem 0 0;
  }

  #block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: -20vh;
    height: 25%;
    width: 100%;
    background-color: transparent;
  }

  #down {
    position: relative;
    right: 0;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 20%;
    color: white;
    background-color: #5498fd;
  }

  #down svg {
    height: 30px;
    width: 30px;
  }

  #snippet {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    width: 100%;
    height: 100%;
    background-color: #ececec;
    border-left: 10px solid #5498fd;
  }

  #snippet h3::before {
    position: absolute;
    content: '';
    display: block;
    height: 10px;
    width: 10px;
    left: 5%;
    bottom: 35%;
    border-left: 2px solid black;
    border-bottom: 2px solid black;
  }

  #snippet h3::after {
    position: absolute;
    content: '';
    display: block;
    height: 10px;
    width: 10px;
    right: 5%;
    top: 35%;
    border-right: 2px solid black;
    border-top: 2px solid black;
  }

  /* menu */
  .mobile {
    height: 60px;
  }

  #menu {
    display: flex;
    align-items: center;
    height: 35px;
  }

  #hamburger {
    position: relative;
    left: -60px;
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0a0a0a;
    cursor: pointer;
  }

  #menu-btn {
    position: relative;
    width: 24px;
    height: 3px;
    background-color: white;
    border-radius: 5px;
  }

  #menu-btn::before {
    right: 0;
  }

  #menu-btn::after {
    left: 0;
  }

  #menu-btn::before,
  #menu-btn::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    border-radius: 5px;
    background-color: white;
    transition: ease-out .2s;
  }

  #menu-btn::before {
    transform: translateY(-8px);
  }

  #menu-btn::after {
    transform: translateY(8px);
  }

  #hamburger:hover #menu-btn::before,
  #hamburger:hover #menu-btn::after {
    width: 24px;
  }

  #color-expand {
    position: absolute;
    width: 80vw;
    right: -80vw;
    height: 100vh;
  }

  #menu-expand {
    position: absolute;
    width: 77vw;
    right: -80vw;
    height: 100vh;
    background-color: #0a0a0a;
  }

  #menu-socials {
    color: white;
    border-top: 1px solid #292929;
    height: 50px;
    padding: 5% 0;
    margin: 0 20px;
    display: flex;
    justify-content: space-around;
  }

  #menu-socials svg{
    transform: scale(1.5);
  }

  #socials {
    display: none;
  }

  /* projects */
  main {
    padding: 80px 5%;
  }

  .project {
    display: flex;
    margin: 60% 0%;
    width: auto;
    height: 45vh;
    background-color: #292929;
    padding: 2.5% 5%;
    transition: cubic-bezier(0, 1, .9, 1) .3s;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .project section {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .project section img{
    position: initial;
    align-self: center;
    padding-top: 0%;
    height: 100%;
    transition: cubic-bezier(0, 1, .9, 1) .3s;
  }

  .project div {
    width: 45%;
    margin-top: 10%;
    line-height: 1.5;
  }

  .project button{
    position: default;
    bottom: 100px;
    left: 4%;
    display: none;
  }

  #date em{
    position: absolute;
    text-align: left;
    top: 75%;
    right: -10px;
    transform: rotate(90deg);
  }

  #colorblock {
    position: absolute;
    display: block;
    bottom: -15px;
    left: 0;
    height: 15px;
    width: 100% ;
    z-index: -1;
    transition: cubic-bezier(0, 1, .9, 1) .4s;
  }

  #desc {
    display: none;
  }

  /* CardType2 */
  .card {
    position: relative;
    margin: 0 25px;
    flex: 0 0 auto;
    width: 250px;
    overflow: hidden;
    cursor: pointer;
    transition: ease-out .3s;
  }

  .scrolling-wrapper {
    padding: 5% 0;
    width: 105%;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    border-left: 1px solid #292929;
    scroll-behavior: smooth;
  }

  /* footer */
  footer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 5%;
    height: calc(100vh - 60px);
    z-index: 9;
    background-image: url('bgimages/testbg.png');
    background-size: contain;
    background-color: #ececec;
  }

  #copy {
    padding-top: 4px;
    text-align: center;
    width: 90vw;
    position: absolute;
    bottom: 5%;
    font-size: .75rem;
    border-top: 1px solid #0a0a0a;
  }
}