#splash, main {
  margin-left: 100px;
}

#splash {
  padding: 80px 10%;
  height: calc(100vh - 160px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #0a0a0a;
  background-image: url('bgimages/testbg.png');
  background-size: contain;
}

#splash h1 {
  margin-top: 8px;
  -webkit-text-stroke: 1px white;
  color: transparent;
}

.image-container {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 0;
}

.mask {
  overflow: hidden;
  display: flex;
  position: relative;
}

#myVideo {
  position: absolute;
  left: 0;
  top: 0;
  height: 101%;
  z-index: 1;
  opacity: .5;
}

.image-container img:nth-child(1){
  position: relative;
  width: 100%;
  filter: grayscale(1);
}

.rellax, .image-container h1 {
  position: absolute;
  width: 100%;
  transform: scale(1.5);
  text-align: center;
  left: 0;
  top: 5%;
  font-size: 7rem;
  z-index: 1;
}

.image-container img:nth-child(2){
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}

.main-bio-container{
  position: relative;
  margin-left: 100px;
  background-color: #0a0a0a;
  z-index: 2;
}

#who p, #skills p {
  margin-top: 0px;
  color: #ececec;
  line-height: 1;
  font-size: 1rem;
  transition: ease-out .2s;
}

#skills p {
  margin: 0;
}

#skills p:hover {
  transform: translateY(-4px);
  color: #5498fd;
}

#skills {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1px;
  grid-auto-rows: minmax(150px, 1fr);
  background-color: #292929;
  margin: 5% 10%;
}

.grid-section {
  background-color: #0a0a0a;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid-section img{
  width: 50%;
}

.side h1 {
  text-transform: uppercase;
  width: 100%;
  transform: rotate(-90deg);
  font-size: 2rem;
  color: #292929;
}

.side h1::before {
  content: '';
  display: block;
  position: absolute;
  right: 0px;
  bottom: -8px;
  width: 50px;
  height: 4px;
  background-color: #5498fd;
}

@media only screen and (max-width: 1000px) {
  #splash, main {
    margin-left: 0px;
  }

  .main-bio-container {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 768px) {
  #splash {
    margin-left: 0px;
  }

  .main-bio-container{
    position: relative;
    margin-left: 0px;
    background-color: #0a0a0a;
    z-index: 2;
  }

  #splash {
    padding: 80px 10%;
    height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #0a0a0a;
    background-image: url('bgimages/testbg.png');
    background-size: contain;
  }

  .rellax, .image-container h1 {
    position: absolute;
    width: 100%;
    transform: scale(1.5);
    text-align: center;
    left: 0;
    top: 5%;
    font-size: 6rem;
    z-index: 1;
  }
}

@media only screen and (max-width: 425px) {
  html {
    font-size: 14px;
  }

  #splash {
    padding: 60px 0 0 0;
    height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #0a0a0a;
    background-image: url('bgimages/testbg.png');
    background-size: contain;
  }

  .image-container {
    position: relative;
    left: 0;
    top: 0px;
    height: 100%;
    width: 100%;
    z-index: 0;
  }

  .rellax, .image-container h1 {
    position: absolute;
    width: 100%;
    transform: scale(1.5);
    text-align: center;
    left: 0;
    top: 5%;
    font-size: 3rem;
    z-index: 1;
  }

  .main-bio-container{
    display: flex;
    flex-direction: column;
  }

  .side h1 {
    text-transform: uppercase;
    width: 100%;
    transform: rotate(0deg);
    font-size: 2rem;
    color: #292929;
  }

  .side h1::before {
    content: '';
    display: block;
    position: absolute;
    left: 0px;
    bottom: -8px;
    width: 50px;
    height: 4px;
    background-color: #5498fd;
  }

  #who, #skills{
    width: 100%;
    margin-left: 0px;
    margin-bottom: 15%;
  }

  #skills {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1px;
    grid-auto-rows: minmax(150px, 1fr);
    background-color: #292929;
  }
}

@media only screen and (max-width: 375px) {
  #splash {
    padding: 60px 0 0 0;
    height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #0a0a0a;
    background-image: url('bgimages/testbg.png');
    background-size: contain;
  }
}